import moment from 'moment'
import { DATE_TIME_FORM_FULL_FORMAT } from '@/constans/date'

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    event: {
      active: 1,
      date: {
        value: `${moment().startOf('month').format(DATE_TIME_FORM_FULL_FORMAT)}|${moment().endOf('month').format(
          DATE_TIME_FORM_FULL_FORMAT,
        )}`,
        operator: 'bwn'
      }
    }
  },
  sortBy: ['id'],
  sortDesc: [true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}