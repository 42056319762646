export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    enabled: 1,
    role: {
      value: '',
      operator: 'in'
    }
  },
  sortBy: ['lastName'],
  sortDesc: [false],
  clientOptions: {
    filterPanelExpanded: false,
  },
}
