import StorageService from '@/services/storage.service'
import storageKeys from '@/constans/storageKeys'
import { defaultFilters } from '@/models/UserReport/helpers'
import eventPointRepository from '@/repository/generatedRepository/eventPointRepository'
import TableOptions from '@/models/TableOptions'
import { omitEmptyDeepFilters, prepareFilters } from '@/helpers/filters'
import usersRepository from "@/repository/generatedRepository/usersRepository";
import i18n from "@/plugins/vue-i18n";
import {DATE_FORM_FORMAT, DATE_FORMAT, DATE_TIME_FORM_FULL_FORMAT} from "@/constans/date";
const { saveAs } = require('file-saver')
import moment from "moment";

const state = {
  list: [],
  filters: StorageService.get(storageKeys.USER_REPORT_FILTERS) || { ...defaultFilters },
  tableOptions: TableOptions.defaultTableOptions(),
}

const getters = {
  filters: state => state.filters,
  tableOptions: state => state.tableOptions,
  list: state => state.list,
  isOpenFilterPanel: state =>
    state.filters.clientOptions && state.filters.clientOptions.filterPanelExpanded,
}

const actions = {
  async loadData(context, payload) {
    const {filters, id} = payload
    context.commit('setFilters', filters)
    StorageService.set(storageKeys.USER_REPORT_FILTERS, filters)

    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const appliedCfFilters = omitEmptyDeepFilters(filters.cfFilter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: {
        ...appliedFilters,
        user: {
          value: id,
          operator: 'in'
        }
      },
      cfFilter: appliedCfFilters,
    })
    const { data } = await eventPointRepository.list(preparedFilters, {headers: {'additional-groups': 'point_event'}})

    context.commit('setList', { data, filters })

    return { products: data.items, pagination: data.pagination }
  },
  async loadExportFile(context, payload) {
    const {id, filters} = payload
    const dates = filters.filter?.event?.date?.value
    const active = filters.filter?.event?.active === null ? null : !!filters.filter.event.active
    let requestDates = dates ? dates.split('|').map(item => moment(item, DATE_TIME_FORM_FULL_FORMAT).format(DATE_FORM_FORMAT)) : null
    requestDates = requestDates ? {dateFrom: requestDates[0], dateTo: requestDates[1]} : null
    const preparedDates = dates ? dates?.split('|').map(item => moment(item, DATE_TIME_FORM_FULL_FORMAT).format(DATE_FORMAT)) : null
    const response = await usersRepository.exportReport(
      { ...requestDates, xlsx: true, id: id, active: active },
        { responseType: 'blob' }
    )
    const blob = new Blob([response.data], { type: response.headers['content-type'] })
    const filename = `${i18n.tc('users.report')}${preparedDates ? ` ${preparedDates.join(' - ')}` : ''}.xlsx`
    saveAs(blob, filename)
    return response
  },
  async searchUserReport(context, filters) {
    const appliedFilters = omitEmptyDeepFilters(filters.filter)
    const preparedFilters = prepareFilters({
      ...filters,
      filter: appliedFilters,
    })

    const { data } = await eventPointRepository.list(preparedFilters)

    return { products: data.items, pagination: data.pagination }
  },
  async loadItem(context, id) {
    const { data } = await eventPointRepository.get(id)
    return data
  },
  createItem(context, payload) {
    return eventPointRepository.post(payload)
  },
  updateItem(context, payload) {
    const { id, ...data } = payload
    return eventPointRepository.put(id, data)
  },
  deleteItem(context, payload) {
    return eventPointRepository.delete(payload)
  },
  async loadItemOneC(context, id) {
    const { data } = await eventPointRepository.getOneC(id)
    return { product: data }
  },
  filtersStorageReset() {
    StorageService.remove(storageKeys.USER_REPORT_FILTERS)
  },
  updateFiltersClientOptions({ commit, getters }, clientOptions) {
    const updatedFilters = {
      ...getters.filters,
      clientOptions,
    }
    commit('setFilters', updatedFilters)
    StorageService.set(storageKeys.USER_REPORT_FILTERS, updatedFilters)
  },
}

const mutations = {
  setList(state, { data, filters }) {
    state.list = [...data.items]
    state.tableOptions = new TableOptions(data.pagination, filters)
  },
  setFilters(state, filters) {
    state.filters = { ...filters }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
