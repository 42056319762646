import moment from "moment";
import {DATE_FORM_FORMAT} from "@/constans/date";
export const defaultFilters = {
    pagination: {
        limit: 20,
        page: 1,
    },
    filter: {
      dateFrom: moment().startOf('month').format(DATE_FORM_FORMAT),
      dateTo: moment().endOf('month').format(DATE_FORM_FORMAT),
      active: 1
    },
    sortBy: ['id'],
    sortDesc: [true],
    clientOptions: {
        filterPanelExpanded: false,
    },
}
