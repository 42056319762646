import moment from "moment";
import {DATE_TIME_FORM_FULL_FORMAT} from "@/constans/date";

export const defaultFilters = {
  pagination: {
    limit: 20,
    page: 1,
  },
  filter: {
    active: 1,
    type: null,
    date: {
      value: `${moment().startOf('month').format(DATE_TIME_FORM_FULL_FORMAT)}|${moment().endOf('month').format(
        DATE_TIME_FORM_FULL_FORMAT,
      )}`,
      operator: 'bwn',
    },
  },
  sortBy: ['active', 'id'],
  sortDesc: [true, true],
  clientOptions: {
    filterPanelExpanded: false,
  },
}

export default function customUserLabel(option) {
  return `${option.name} ${option.lastName}`
}